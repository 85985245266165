// AboutUsSection.js
import React from 'react';
import '../styles/AboutUsSection.css';

function AboutUsSection() {
  return (
    <section id="aboutus" className="aboutus-section">
      <h2>Our Journey</h2>
      <p>
        KonnectTaps was born out of a desire to simplify and modernize the way we network. In a rapidly evolving digital world, we provide tools that seamlessly integrate personal interactions with online engagement, empowering you to showcase your unique identity effortlessly.
      </p>
      <img src="/images/group.jpg" alt="KonnectTaps Team" data-aos="fade-up" />
    </section>
  );
}

export default AboutUsSection;