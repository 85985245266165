// PricingSection.js
import React from 'react';
import { Link } from 'react-scroll';
import '../styles/PricingSection.css';

function PricingSection() {
  return (
    <section id="pricing" className="pricing-section">
      <h2>Flexible Plans for Everyone</h2>
      <div className="pricing-container">
        <div className="plan" data-aos="fade-up">
          <h3>Basic</h3>
          <p className="price">Free</p>
          <ul>
            <li>Personalized digital page</li>
            <li>Links and text content</li>
            <li>Header customization</li>
            <li>Color themes</li>
            <li>Privacy mode</li>
            <li>Social media integration</li>
          </ul>
          <Link to="waitlist" smooth={true} duration={500}>
            <button>Get Started for Free</button>
          </Link>
        </div>
        <div className="plan popular" data-aos="fade-up" data-aos-delay="100">
          <div className="badge">Most Popular</div>
          <h3>Premium</h3>
          <p className="price">$10<span>/month</span></p>
          <ul>
            <li>All Basic features</li>
            <li>Embedded multimedia content</li>
            <li>Collect customer contacts</li>
            <li>Photo galleries</li>
            <li>Advanced analytics</li>
          </ul>
          <Link to="waitlist" smooth={true} duration={500}>
            <button>Upgrade to Premium</button>
          </Link>
        </div>
        <div className="plan" data-aos="fade-up" data-aos-delay="200">
          <h3>Enterprise</h3>
          <p className="price">Contact Us</p>
          <ul>
            <li>All Premium features</li>
            <li>Team management tools</li>
            <li>Custom solutions</li>
            <li>Dedicated support</li>
          </ul>
          <Link to="contact" smooth={true} duration={500}>
            <button>Contact Us</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default PricingSection;