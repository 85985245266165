// ContactSection.js
import React, { useState } from 'react';
import '../styles/ContactSection.css';

function ContactSection() {
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    agreedToPromo: false,
  });
  const [confirmation, setConfirmation] = useState('');

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    const scriptURL =
      'https://script.google.com/macros/s/AKfycbwqg6Qp_TakFU59kER2CP2jh_UsO5uU2bMoCchpES-jyFMcsxJ1XWuezqxiJGMA6nxd/exec'; // Same URL as Waitlist

    // Construct URL-encoded form data
    const formData = new URLSearchParams();
    formData.append('formType', 'contactus');
    formData.append('name', contactData.name);
    formData.append('email', contactData.email);
    formData.append('subject', contactData.subject);
    formData.append('message', contactData.message);
    formData.append('agreedToPromo', contactData.agreedToPromo);

    try {
      await fetch(scriptURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
        mode: 'no-cors', // Bypass CORS restrictions
      });

      // Since we can't read the response, assume success
      setConfirmation(
        "Thank you for reaching out! We've received your message and will get back to you shortly."
      );
      // Reset the form
      setContactData({
        name: '',
        email: '',
        subject: '',
        message: '',
        agreedToPromo: false,
      });
    } catch (error) {
      console.error('Error:', error);
      setConfirmation('There was an error submitting your request.');
    }
  };

  return (
    <section id="contact" className="contact-section">
      <h2>Let's Connect</h2>
      <p>
        Questions, comments, or just want to say hello? We'd love to hear from
        you!
      </p>
      <div className="contact-container">
        <div className="contact-details" data-aos="fade-right">
          <p>
            <strong>Email:</strong> contact@konnecttaps.com
          </p>
          <p>
            <strong>Follow us on:</strong>
          </p>
          <div className="social-icons">
            <a
              href="https://www.tiktok.com/@konnecttaps"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok"></i>
            </a>
            <a
              href="https://www.instagram.com/konnecttaps/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <form
          onSubmit={handleContactSubmit}
          className="contact-form"
          data-aos="fade-left"
        >
          <input
            type="text"
            name="name"
            placeholder="Name"
            required
            value={contactData.name}
            onChange={(e) =>
              setContactData({ ...contactData, name: e.target.value })
            }
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            required
            value={contactData.email}
            onChange={(e) =>
              setContactData({ ...contactData, email: e.target.value })
            }
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={contactData.subject}
            onChange={(e) =>
              setContactData({ ...contactData, subject: e.target.value })
            }
          />
          <textarea
            name="message"
            placeholder="Message"
            required
            value={contactData.message}
            onChange={(e) =>
              setContactData({ ...contactData, message: e.target.value })
            }
          ></textarea>
          <div className="form-controls">
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="agreedToPromo"
                checked={contactData.agreedToPromo}
                onChange={(e) =>
                  setContactData({
                    ...contactData,
                    agreedToPromo: e.target.checked,
                  })
                }
              />
              <label className="checkbox-label">
                I agree to receive promotional emails from KonnectTaps.
              </label>
            </div>
            <button type="submit">Send Message</button>
          </div>
        </form>
      </div>
      {confirmation && <p className="form-message">{confirmation}</p>}
    </section>
  );
}

export default ContactSection;