import React, { useState } from 'react';
import '../styles/WaitlistSection.css';

function WaitlistSection() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    industry: '',
    agreedToPromo: false
  });
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const scriptURL = 'https://script.google.com/macros/s/AKfycbwqg6Qp_TakFU59kER2CP2jh_UsO5uU2bMoCchpES-jyFMcsxJ1XWuezqxiJGMA6nxd/exec';
  
    const body = `formType=waitlist&firstName=${encodeURIComponent(formData.firstName)}&lastName=${encodeURIComponent(formData.lastName)}&email=${encodeURIComponent(formData.email)}&industry=${encodeURIComponent(formData.industry)}&agreedToPromo=${encodeURIComponent(formData.agreedToPromo)}`;
  
    try {
      await fetch(scriptURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
        mode: 'no-cors', // This tells the browser to not enforce CORS
      });
  
      // Since we cannot read the response, we'll assume it was successful
      setMessage("Thank you for joining! We'll notify you as soon as we're ready for you.");
    } catch (error) {
      console.error('Error:', error);
      setMessage('There was an error submitting your request.');
    }
  };

  return (
    <section id="waitlist" className="waitlist-section">
      <h2>Be the First to Experience KonnectTaps</h2>
      <p>
        Our platform is currently in closed beta. Join our waitlist to get exclusive early access and start revolutionizing your networking experience.
      </p>
      <form onSubmit={handleSubmit} className="waitlist-form">
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          required
          value={formData.firstName}
          onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          required
          value={formData.lastName}
          onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          required
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <input
          type="text"
          name="industry"
          placeholder="Industry (Optional)"
          value={formData.industry}
          onChange={(e) => setFormData({ ...formData, industry: e.target.value })}
        />

        <div className="form-controls">
          <div className="checkbox-container">
            <input
              type="checkbox"
              name="agreedToPromo"
              checked={formData.agreedToPromo}
              onChange={(e) => setFormData({ ...formData, agreedToPromo: e.target.checked })}
            />
            <label className="checkbox-label">
              I agree to receive promotional emails from KonnectTaps.
            </label>
          </div>
          <button type="submit">Join the Waitlist</button>
        </div>
      </form>
      {message && <p className="form-message">{message}</p>}
    </section>
  );
}

export default WaitlistSection;
