// TeamSection.js
import React from 'react';
import '../styles/TeamSection.css';

function TeamSection() {
  const teamMembers = [
    {
      name: 'George Salib',
      title: 'CEO',
      image: '/images/team/george-salib.jpg',
      bio: 'George leads with vision and passion, driving KonnectTaps to new heights in the NFC technology space.',
    },
    {
      name: 'Bishoy Azer',
      title: 'COO',
      image: '/images/team/bishoy-azer.jpg',
      bio: 'Bishoy ensures our operations are smooth and efficient, bringing expertise in strategic management.',
    },
    {
      name: 'Amir Ibrahim',
      title: 'VP of Software Development',
      image: '/images/team/amir-ibrahim.jpg',
      bio: 'Amir architects our innovative solutions, turning ideas into functional, user-friendly products.',
    },
    {
      name: 'Peter Wahba',
      title: 'VP of Sales and Finance',
      image: '/images/team/peter-whaba.jpg',
      bio: 'Peter drives growth with a keen eye on market trends and financial health.',
    },
    {
      name: 'Michael Abdelmalek',
      title: 'Multimedia Content Creator',
      image: '/images/team/michael-abdelmalek.jpg',
      bio: 'Michael crafts the visual stories that define our brand and connect with our audience.',
    },
  ];

  return (
    <section id="team" className="team-section">
      <h2>The Minds Behind KonnectTaps</h2>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div className="team-member" data-aos="fade-up" data-aos-delay={index * 100} key={index}>
            <img src={member.image} alt={member.name} />
            <h3>{member.name}</h3>
            <p className="title">{member.title}</p>
            <p className="bio">{member.bio}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TeamSection;