// HowItWorksSection.js
import React from 'react';
import '../styles/HowItWorksSection.css';

function HowItWorksSection() {
  return (
    <section id="howitworks" className="howitworks-section">
      <h2>Effortless Networking in Three Steps</h2>
      <div className="steps-container">
        <div className="step" data-aos="fade-up">
          <div className="step-number">1</div>
          <h3>Create Your Page</h3>
          <p>
            Sign up and use our intuitive editor to build your personalized digital page. Customize it with your branding, links, and content to showcase your unique identity.
          </p>
        </div>
        <div className="step" data-aos="fade-up" data-aos-delay="100">
          <div className="step-number">2</div>
          <h3>Share Your Custom Link</h3>
          <p>
            Share your custom URL with anyone, anywhere. Whether it's on social media, email signatures, or online profiles, your digital page is just a click away for your audience.
          </p>
        </div>
        <div className="step" data-aos="fade-up" data-aos-delay="200">
          <div className="step-number">3</div>
          <h3>Connect with a Tap</h3>
          <p>
            Use our smart NFC cards to share your digital page instantly during in-person interactions. Simply tap your card on any NFC-enabled smartphone to make a lasting impression.
          </p>
        </div>
      </div>
    </section>
  );
}

export default HowItWorksSection;