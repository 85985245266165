// HeroSection.js
import React from 'react';
import { Link } from 'react-scroll';
import '../styles/HeroSection.css';

function HeroSection() {
  return (
    <section id="hero" className="hero-section">
      <div className="hero-content" data-aos="fade-right">
        <h1>Tap, Share, Go</h1>
        <h2>Revolutionize your branding with KonnectTaps</h2>
        <p>
          Elevate your networking with customizable digital pages and innovative smart NFC business cards.
        </p>
        <Link to="waitlist" smooth={true} duration={500} className="cta-button">
          Join the Waitlist
        </Link>
      </div>
      <div className="hero-image" data-aos="fade-left">
        <img src="/images/hero-image.jpg" alt="Networking with KonnectTaps" />
      </div>
    </section>
  );
}

export default HeroSection;